import { useEffect, useMemo, useState } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import { useDispatch } from 'react-redux';
import {
  OrderOrchestrationStatus,
  OrderPaymentStatus,
  PostActivationOrderResponse,
  PostPaymentOrderResponse,
  PostProductUpdateResponse,
} from '@dtp/membership-service-types';
import useSelector from '../redux/typedHooks';
import { actions, OrderActionType } from '../redux/modules/myMembership';
import { useSessionStorage } from './useSessionStorage';
import { useAuth0Token } from './useAuth0Token';

export const waitingCodes = [
  OrderOrchestrationStatus.UNKNOWN, // 0
  OrderOrchestrationStatus.STARTED, // 1
  OrderOrchestrationStatus.RUNNING, // 10
  OrderOrchestrationStatus.PAID, // 200
  OrderOrchestrationStatus.WAITING, // 220
];

export const useRunningUpdates = (): Record<
  | PostPaymentOrderResponse['orchestrationId']
  | PostProductUpdateResponse['orchestrationId']
  | PostActivationOrderResponse['orchestrationId'],
  PostPaymentOrderResponse | PostProductUpdateResponse | PostActivationOrderResponse
> => {
  const dispatch = useDispatch();
  const { simpleToken } = useAuth0Token();

  const [runningUpdates, setRunningUpdates] = useState<
    Record<
      | PostPaymentOrderResponse['orchestrationId']
      | PostProductUpdateResponse['orchestrationId']
      | PostActivationOrderResponse['orchestrationId'],
      PostPaymentOrderResponse | PostProductUpdateResponse | PostActivationOrderResponse
    >
  >({});

  const [connection, setConnection] = useState<HubConnection | null>(null);
  const { apimBaseUrl, apimMembershipApi, signalR, apimContentHub } = useSelector((state) => state.application);
  const [orchestrationsInSessionStorage, setOrchestrationsInSessionStorage] = useSessionStorage<Record<string, any>>(
    'orchestrationIds',
    {},
    true,
  );

  const orchestrationIdArray = useMemo(
    () => Object.keys(orchestrationsInSessionStorage),
    [orchestrationsInSessionStorage],
  );
  const orchestrationIdsMap = useMemo(
    () => orchestrationsInSessionStorage as Record<string, any>,
    [orchestrationsInSessionStorage],
  );

  const orchestrationIdsFromRedux = useSelector((state) => state.myMembership.orchestrationIds);

  // When orchestration ids from redux change - move the ones that are not in session storage to session storage.
  useEffect(() => {
    if (Object.values(orchestrationIdsFromRedux).length !== 0 && typeof orchestrationIdArray !== 'undefined') {
      // add orchestrations id that are not marked as "added to session storage" to session storage
      // don't add Circle K EXTRA (8043), this is done in the ActivateCircleK page
      const newValuesFromRedux = Object.entries(orchestrationIdsFromRedux)
        .filter((entry) => entry[1].isAddedToSessionStorage === false && entry[1].productId !== '8043')
        .reduce((acc: Record<string, any>, cur) => {
          acc[cur[0]] = { ...cur[1], isAddedToSessionStorage: true };
          return acc;
        }, {});

      if (Object.values(newValuesFromRedux).length > 0) {
        setOrchestrationsInSessionStorage((previous) => ({ ...previous, ...newValuesFromRedux }));
      }
      // Confirm that these have been added to session storage
      Object.keys(orchestrationIdsFromRedux).forEach((orchestrationId) => {
        dispatch(actions.confirmOrchestrationInSessionStorage(orchestrationId));
      });
    }
  }, [dispatch, setOrchestrationsInSessionStorage, orchestrationIdArray, orchestrationIdsFromRedux]);

  useEffect(() => {
    if (apimContentHub && orchestrationIdArray && apimBaseUrl && apimMembershipApi && signalR && !connection) {
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${signalR}/${apimMembershipApi}`, {
          headers: {
            'Ocp-Apim-Subscription-Key': apimContentHub,
          },
          withCredentials: false,
        })
        .withAutomaticReconnect()
        .build();
      setConnection(newConnection);
    }
  }, [apimContentHub, orchestrationIdArray, apimBaseUrl, apimMembershipApi, signalR, connection]);

  // Function subscribing to signalR
  const startConnection = useMemo(() => {
    if (
      connection &&
      apimContentHub &&
      apimMembershipApi &&
      orchestrationIdArray &&
      connection &&
      dispatch &&
      orchestrationIdsMap &&
      setOrchestrationsInSessionStorage &&
      signalR &&
      simpleToken
    ) {
      return async () => {
        try {
          await connection.start();
          orchestrationIdArray.forEach((orchestrationId) => {
            connection.on(orchestrationId, (rawMessage: string) => {
              const message: PostActivationOrderResponse | PostPaymentOrderResponse | PostProductUpdateResponse =
                JSON.parse(rawMessage);
              if (orchestrationIdArray.includes(message.orchestrationId)) {
                setRunningUpdates((oldRunningUpdates) => {
                  const newRunningUpdates = cloneDeep(oldRunningUpdates);
                  // If the order is still waiting, continue listening
                  // if it is a purchase and it is paid
                  // or if it is an activation/update
                  if (
                    waitingCodes.includes(message.status) &&
                    ((message.type === 'Payment' && message.paymentOrderStatus === OrderPaymentStatus.PAID) ||
                      ['Activation', 'Update'].includes(message.type))
                  ) {
                    newRunningUpdates[message.orchestrationId] = message;
                  } else {
                    if (typeof newRunningUpdates[message.orchestrationId] !== 'undefined') {
                      delete newRunningUpdates[message.orchestrationId];
                    }
                    setOrchestrationsInSessionStorage((oldSessionStorageItems) => {
                      const newItems = cloneDeep(oldSessionStorageItems);
                      delete newItems[message.orchestrationId];
                      return newItems;
                    });
                  }
                  return newRunningUpdates;
                });
                if (simpleToken && message.status === OrderOrchestrationStatus.DONE) {
                  dispatch(actions.getMyMembership.request(simpleToken, {}));
                }
              }
            });
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('Connection failed');
        }

        // Get initial state for all orchestration ids
        try {
          const res = await Promise.all(
            orchestrationIdArray.map(async (orchestrationId) => {
              const headers: {
                'Ocp-Apim-Subscription-Key': string;
                Authorization?: string;
              } = { 'Ocp-Apim-Subscription-Key': apimContentHub };

              if (simpleToken) {
                headers.Authorization = `Bearer ${simpleToken}`;
              }

              if (orchestrationIdsMap[orchestrationId].type === OrderActionType.ProductPurchase) {
                return axios.get<PostPaymentOrderResponse>(
                  `${signalR}/${apimMembershipApi}/v2/products/payment/${orchestrationId}`,
                  {
                    headers,
                  },
                );
              }
              if (orchestrationIdsMap[orchestrationId].type === OrderActionType.ProductActivation) {
                return axios.get<PostActivationOrderResponse>(
                  `${signalR}/${apimMembershipApi}/v2/products/activation/${orchestrationId}`,
                  {
                    headers,
                  },
                );
              }
              return axios.get<PostProductUpdateResponse>(
                `${signalR}/${apimMembershipApi}/v2/products/update/${orchestrationId}`,
                {
                  headers,
                },
              );
            }),
          );

          // Remove item from session storage when status is done
          res.forEach((response) => {
            // Stop listening if the order has status done or if it is a payment product and it is unpaid
            if (
              !waitingCodes.includes(response.data.status) ||
              (response.data.paymentOrderStatus !== OrderPaymentStatus.PAID && response.data.type === 'Payment')
            ) {
              const newItemsInSessionStorage = cloneDeep(orchestrationIdsMap) as Record<string, any>;
              if (newItemsInSessionStorage) {
                if (newItemsInSessionStorage[response.data.orchestrationId]) {
                  delete newItemsInSessionStorage[response.data.orchestrationId];
                }
                setOrchestrationsInSessionStorage(newItemsInSessionStorage);
              }
            }
          });
          const initialRunningUpdates = res.reduce(
            (
              acc: Record<
                | PostPaymentOrderResponse['orchestrationId']
                | PostProductUpdateResponse['orchestrationId']
                | PostActivationOrderResponse['orchestrationId'],
                PostPaymentOrderResponse | PostProductUpdateResponse | PostActivationOrderResponse
              >,
              cur,
            ) => {
              if (waitingCodes.includes(cur.data.status)) {
                acc[cur.data.orchestrationId] = cur.data;
              }
              return acc;
            },
            {},
          );

          setRunningUpdates(initialRunningUpdates);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      };
    }
    return null;
  }, [
    apimContentHub,
    apimMembershipApi,
    connection,
    dispatch,
    orchestrationIdArray,
    orchestrationIdsMap,
    setOrchestrationsInSessionStorage,
    signalR,
    simpleToken,
  ]);

  // Function cleaning up signalR subscription
  const endConnection = useMemo(() => {
    if (connection) {
      return async () => {
        if (connection) {
          try {
            await connection.stop();
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Connection could not be stopped!');
          }
        }
      };
    }
    return null;
  }, [connection]);

  useEffect(() => {
    if (startConnection && endConnection) {
      endConnection().then(() => {
        startConnection().then();
        return () => {
          endConnection().then();
        };
      });
    }
    return () => {};
  }, [startConnection, endConnection]);

  return runningUpdates;
};
